import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <section className="hero is-fullheight-with-navbar">
            <div className="hero-body container has-text-centered">
                <div className="content">
                    <h1>404: NOT FOUND</h1>
                    <p>You just hit a route that doesn&#39;t exist, sorry!</p>
                    <p>
                        If you think this might be an error on our end, or, you know, if you just want to chat --{" "}
                        <Link to="/contact">Contact Us</Link>!
                    </p>
                </div>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
